<template>
  <div class="p-10">
    <!-- <ManageUsers /> -->
    <!-- <Settings /> -->
    <h2 class="text-primary">Usage Overview</h2>
    <v-row>
      <v-col cols="4">
        <v-card flat class="p-0 rounded-lg">
          <v-card-title>
            Total Users
          </v-card-title>
          <div
            style="display:flex; flex-direction:row;align-items: center;"
            class="pb-6"
          >
            <div
              class="font-weight-bolder px-4 text-primary"
              style="font-size:3.3rem;"
            >
              100
            </div>

            <v-spacer />
            <div class="mr-5" style="min-width: 40%;display: flex;height:100%">
              <v-sparkline
                :fill="false"
                :gradient="['red', 'orange', 'yellow']"
                :smooth="'10'"
                :value="value"
                line-width="7"
                auto-draw
                padding="0"
              ></v-sparkline>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card flat class="p-0 rounded-lg">
          <v-card-title>
            Total Doctors
          </v-card-title>
          <div
            style="display:flex; flex-direction:row;align-items: center;"
            class="pb-6"
          >
            <div
              class="font-weight-bolder px-4 text-primary"
              style="font-size:3.3rem;"
            >
              100
            </div>

            <v-spacer />
            <div class="mr-5" style="min-width: 40%;display: flex;height:100%">
              <v-sparkline
                :fill="false"
                :gradient="['red', 'orange', 'yellow']"
                :smooth="'10'"
                :value="value"
                line-width="7"
                auto-draw
                padding="0"
              ></v-sparkline>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card flat class="p-0 rounded-lg">
          <v-card-title>
            Total Patients
          </v-card-title>
          <div
            style="display:flex; flex-direction:row;align-items: center;"
            class="pb-6"
          >
            <div
              class="font-weight-bolder px-4 text-primary"
              style="font-size:3.3rem;"
            >
              100
            </div>

            <v-spacer />
            <div class="mr-5" style="min-width: 40%;display: flex;height:100%">
              <v-sparkline
                :fill="false"
                :gradient="['red', 'orange', 'yellow']"
                :smooth="'10'"
                :value="value"
                line-width="7"
                auto-draw
                padding="0"
              ></v-sparkline>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-card flat class="p-0 rounded-lg" style="border-radius: 8px 8px 0 0 !important;">
          <v-card-title>
            Total Questions
          </v-card-title>
          <div
            style="display:flex; flex-direction:row;align-items: center;"
            class=""
          >
            <div
              class="font-weight-bolder px-4 text-primary"
              style="font-size:3rem;"
            >
              100
            </div>

            <v-spacer />
            <div class="mr-5" style="min-width: 40%;display: flex;height:100%">
              <v-sparkline
                :fill="false"
                :gradient="['red', 'orange', 'yellow']"
                :smooth="'10'"
                :value="value"
                line-width="7"
                auto-draw
                padding="0"
              ></v-sparkline>
            </div>
          </div>
          <v-divider class="py-0 my-0"/>
        </v-card>
        <v-card flat class="p-0" style="border-radius:0">
          <v-card-title>
            Total Answered
          </v-card-title>
          <div
            style="display:flex; flex-direction:row;align-items: center;"
          >
            <div
              class="font-weight-bolder px-4 text-primary"
              style="font-size:3rem;"
            >
              100
            </div>

            <v-spacer />
            <div class="mr-5" style="min-width: 40%;display: flex;height:100%">
              <v-sparkline
                :fill="false"
                :gradient="['red', 'orange', 'yellow']"
                :smooth="'10'"
                :value="value"
                line-width="7"
                auto-draw
                padding="0"
              ></v-sparkline>
            </div>
          </div>
          <v-divider class="py-0 my-0"/>
        </v-card>
        <v-card flat class="p-0 rounded-lg" style="border-radius: 0 0 8px 8px!important;">
          <v-card-title>
            Total Unanswered
          </v-card-title>
          <div
            style="display:flex; flex-direction:row;align-items: center;"
          >
            <div
              class="font-weight-bolder px-4 text-primary"
              style="font-size:3rem;"
            >
              100
            </div>

            <v-spacer />
            <div class="mr-5" style="min-width: 40%;display: flex;height:100%">
              <v-sparkline
                :fill="false"
                :gradient="['red', 'orange', 'yellow']"
                :smooth="'10'"
                :value="value"
                line-width="7"
                auto-draw
                padding="0"
              ></v-sparkline>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="8">
        <v-card flat class="p-0 rounded-lg" style="min-height:100%">
          <v-card-title>
            By Department
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import Settings from './comp/Settings';
// import ManageUsers from './comp/ManageUsers';
export default {
  name: "AdminDashboard",
  components: {
    // ManageUsers,
    // Settings,
  },
  data() {
    return {
      value: [0, 2, 5, 9, 5, 10, 3, 5,1,10],
    };
  },
};
</script>

<style></style>
